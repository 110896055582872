import React, { useState } from 'react';
import { ButtonForm, Container } from './styles';
import Search from '~/components/Search';
import FormDefault from '~/components/FormDefault';
import { usePedidoVenda } from './PedidoVendaContext';

import { Tabs, Tab, Spinner } from 'react-bootstrap';
import { Pedido } from './screens/pedido';
import { Financeiro } from './screens/financeiro';
import { Entrega } from './screens/entrega';
import Tooltip from '@atlaskit/tooltip';
import { FaPrint } from 'react-icons/fa';
import ImpressaoPedidoVenda from '~/components/ImpressaoPedidoVenda';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

export const PedidoVendaContent: React.FC = () => {
  const MySwal = withReactContent(Swal);
  const {
    onRowClick,
    onNewData,
    formPedidoVenda: { getValues },
    showSearch,
    setShowSearch,
    isUpdate,
    isCancelada,
    isTransmitir,
    currentTab,
    setCurrentTab,
    resetFormData,
    handlePrint,
    handleCancelarPedidoVenda,
    handleTransmitirPedidoVenda,
    onSave,
    isDisabledPedido,
    setShowModalModeloImpressao,
    showModalModeloImpressao,
  } = usePedidoVenda();

  const [isLoadingImprimir, setIsLoadingImprimir] = useState(false);
  const [isLoadingTransmitir, setIsLoadingTransmitir] = useState(false);

  const handleTransmitir = async () => {
    const shouldTransmitir = await MySwal.fire({
      title: ``,
      text: 'Deseja realmente emitir a NFCe com valor fiscal?',
      showCancelButton: true,
      confirmButtonColor: '#07289e',
      cancelButtonColor: '#ff7b7b',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        setIsLoadingTransmitir(true);
        await handleTransmitirPedidoVenda();
        setIsLoadingTransmitir(false);
      }
    });
    return shouldTransmitir;
  };

  const ButtonTransmitir = () => {
    return (
      <Tooltip position="bottom" content="Transmitir">
        <ButtonForm
          onClick={handleTransmitir}
          disabled={isLoadingTransmitir || false}
          className="transmitir"
          type="button"
        >
          {isLoadingTransmitir === true ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <>
              <AiOutlineCloudUpload size={18} color="#fff" />
              Transmitir
            </>
          )}
        </ButtonForm>
      </Tooltip>
    );
  };

  // Botão Customizável para Atualizar Status
  // const ButtonAtualizarStatus = () => {
  //   return (
  //     <Tooltip position="bottom" content="Atualizar Status">
  //       <ButtonForm
  //         onClick={() => console.log('AtualizarStatus')}
  //         disabled={isLoadingAtualizarStatus || false}
  //         className="reprocess"
  //       >
  //         {isLoadingAtualizarStatus === true ? (
  //           <Spinner animation="border" variant="light" size="sm" />
  //         ) : (
  //           <>
  //             <FaRegEdit fontSize="1.25rem" color="#fff" />
  //             Atualizar Status
  //           </>
  //         )}
  //       </ButtonForm>
  //     </Tooltip>
  //   );
  // };

  const ButtonImprimir = () => {
    return (
      <Tooltip position="bottom" content="Imprimir">
        <ButtonForm
          onClick={() => setShowModalModeloImpressao(!showModalModeloImpressao)}
          disabled={isLoadingImprimir || false}
          className="imprimir"
          type="button"
        >
          {isLoadingImprimir === true ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <>
              <FaPrint fontSize="1.25rem" color="#fff" />
              Imprimir
            </>
          )}
        </ButtonForm>
        <ImpressaoPedidoVenda
          show={showModalModeloImpressao}
          handleShow={setShowModalModeloImpressao}
          handlePrint={handlePrint}
        />
      </Tooltip>
    );
  };

  return (
    <Container>
      {showSearch && (
        <Search codTela={302} newData={onNewData} onRowClick={onRowClick} />
      )}

      {!showSearch && (
        <FormDefault
          codTela={302}
          title="Pedido de Venda"
          codigoRegistro={[
            {
              value: getValues('num_pedido'),
              des_campo: 'Código',
            },
            {
              value: getValues('des_tipo_status'),
              des_campo: 'Status',
            },
          ]}
          isDisabledSave={isDisabledPedido}
          onSave={async () => {
            await onSave();
          }}
          isUpdate={isUpdate}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          onNew={onNewData}
          onDelete={handleCancelarPedidoVenda}
          isDeactivateDeleteMessage
          onClearFields={resetFormData}
          onReturnSearch={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isSave={false}
          labelButtonTrash="Cancelar Pedido"
          // customButtons={<ButtonAtualizarStatus />}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {isCancelada && (
                <h3
                  style={{
                    color: 'red',
                    fontSize: '1.625rem',
                    padding: '0.3125rem',
                    lineHeight: '2.4375rem',
                    borderRadius: '0.3125rem',
                    backgroundColor: 'yellow',
                    marginLeft: '0.3rem',
                    marginTop: '0.3rem',
                  }}
                >
                  CANCELADO
                </h3>
              )}
            </div>

            {isUpdate && (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {isTransmitir && <ButtonTransmitir />}
                <ButtonImprimir />
              </div>
            )}
          </div>
          <Tabs
            id="controlled-produto"
            activeKey={currentTab}
            onSelect={(tab: any) => setCurrentTab(tab)}
          >
            <Tab eventKey="pedido" title="Pedido">
              <Pedido />
            </Tab>
            <Tab eventKey="financeiro" title="Financeiro">
              <Financeiro />
            </Tab>
            <Tab eventKey="entrega" title="Entrega">
              <Entrega />
            </Tab>
          </Tabs>
        </FormDefault>
      )}
      {!isUpdate && showModalModeloImpressao && (
        <ImpressaoPedidoVenda
          show={showModalModeloImpressao}
          handleShow={setShowModalModeloImpressao}
          handlePrint={handlePrint}
        />
      )}
    </Container>
  );
};
